import moment from 'moment-timezone';

// export function sortArObject(arrObject, sortBy, direction) {
//   if (arrObject) {
//     let funDirection = direction;
//     if (!funDirection) funDirection = 'asc';
//     return _.orderBy(arrObject, [sortBy], [funDirection]);
//   }
//   return undefined;
// }
//

export function formatWithTimeZone(time) {
  const result = moment.tz(time, 'Asia/Ho_Chi_Minh').format('HH:mm:ss DD/MM/YYYY');
  return result || null;
}

function isTextInclude(text, _searchKey) {
  if (_searchKey) {
    const searchKey = _searchKey.toString().trim().toLowerCase();
    return !!(text && text.toString().includes(searchKey));
  }
  return false;
}

function isObjectInclude(item, search) {
  const values = Object.values(item);
  if (values.length > 0) {
    for (let i = 0; i < values.length; i += 1) {
      let value = values[i];
      if (value) {
        value = value.toString().trim().toLowerCase();
        if (isTextInclude(value, search)) {
          return item;
        }
      }
    }
  }
  return null;
}

// eslint-disable-next-line import/prefer-default-export
export function filters(arr, searchKey) {
  const resultFilters = [];
  arr.forEach((item) => {
    const objectInclude = isObjectInclude(item, searchKey);
    if (objectInclude) {
      resultFilters.push(objectInclude);
    }
  });
  if (resultFilters && resultFilters.length > 0) {
    return resultFilters;
  }
  return null;
}

export function removeEmptyString(obj) {
  const object = { ...obj };
  Object.keys(object).forEach((key) => {
    if (object[key] === '' || object[key] === null || object[key] === undefined) {
      delete object[key];
    }
  });
  return object;
}
